<template>
  <b-card title="">
    <h4>Add organisation to group</h4>
    <b-form-group
            label="Organisation"
            label-for="org"
            class="org-select"
    >
      <v-select
              v-model="newOrg"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orgOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="org"
      >
        <span slot="no-options">
          There are no more organisations left to add.
        </span>
      </v-select>

      <b-button
              variant="primary"
              :disabled="(newOrg === null)"
              @click="addOrg()"
      >
        Add
      </b-button>
    </b-form-group>
    <h4 class="mt-3">Organisations in group</h4>
    <ul class="list-unstyled">
      <li
        v-for="org in orgsData"
        :key="org.id"
        class="list-unstyled"
      >
        <b-media vertical-align="center" class="pb-1 mb-1 border-bottom">
          <template #aside>
            <b-avatar
              size="32"
              :src="org.icon_url"
              :text="avatarText(org.name)"
              :to="{ name: 'orgs-view', params: { id: org.id } }"
              square
            />
          </template>
          <b-link
            :to="{ name: 'orgs-view', params: { id: org.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ org.name }}
          </b-link>
          <b-badge
            v-if="org.enabled"
            pill
            :variant="`light-${resolveUserEnabledVariant(org.enabled)}`"
            class="text-capitalize"
          >
            Active
          </b-badge>
          <b-badge
            v-else
            pill
            :variant="`light-${resolveUserEnabledVariant(org.enabled)}`"
            class="text-capitalize"
          >
            Inactive
          </b-badge>
          <b-button
            class="btn-sm ml-1"
            variant="outline-warning"
            @click="removeOrg(org.id)"
          >
            Remove
          </b-button>
        </b-media>

      </li>
    </ul>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BLink, BBadge, BFormGroup, BButton
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useUsersList from '@/views/user/users-list/useUsersList'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BImg,
    BLink,
    BBadge,
    BMedia,
    BAvatar,
    BFormGroup,
    BButton,
    vSelect,
  },
  setup() {
    const orgsData = ref(null)
    const newOrg = ref(null)
    const orgOptions = ref([])

    const {
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserEnabledVariant,
    } = useUsersList()

    store.dispatch('app/fetchPermissionOrgs', { id: router.currentRoute.params.id, queryParams: { perPage: 1000, sortBy: 'name' } })
      .then(response => {
        orgsData.value = response.data.results
        store.dispatch('app/fetchOrgs', { perPage: 1000, sortBy: 'name' })
          .then(response2 => {
            response2.data.results.forEach(org => {
              if (orgsData.value.filter(orgInGroup => orgInGroup.id === org.id).length === 0) {
                orgOptions.value.push({ label: org.name, value: org.id })
              }
            })
            // orgOptions.value = response2.data.results.map(item => ({ label: item.name, value: item.id }))
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error creating new user.',
                text: 'ERROR: ' + error.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: 'ERROR: ' + error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

    const addOrg = () => {
      // add org
      store.dispatch('app/permissionAddOrg', { id: router.currentRoute.params.id, orgId: newOrg.value })
        .then(response => {
          // add result to orgs list
          orgsData.value.push(response.data.results[0])
          // remove form dropdown
          orgOptions.value = orgOptions.value.filter(org => org.value !== newOrg.value)
          newOrg.value = null
        })
        .catch(error => {
          // TODO alert user to error
          console.log(error.response.data)
        })
    }

    const removeOrg = id => {
      store.dispatch('app/permissionRemoveOrg', { id: router.currentRoute.params.id, orgId: id })
        .then(response => {
          // remove result from orgs list
          orgsData.value = orgsData.value.filter(org => org.id !== id)
          // add to dropdown
          orgOptions.value.push({ label: response.data.results[0].name, value: response.data.results[0].id })
        })
        .catch(error => {
          // TODO alert user to error
          console.log(error.response.data)
        })
    }

    return {
      orgsData,
      orgOptions,
      newOrg,
      addOrg,
      removeOrg,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserEnabledVariant,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
