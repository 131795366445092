<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :org-options="orgOptions"
      :org-id="orgData.id"
      :country="orgData.country"
      @refetch-data="refetchData"
    />
    <b-card
      no-body
    >
      <div class="card-body">
        <div class="card-title d-flex justify-content-between align-items-center">
          <div>{{ orgData.member_count }} Users</div>
          <b-button
            class="ml-2"
            variant="primary"
            @click="isAddNewUserSidebarActive = true"
          >
            <span class="text-nowrap">Add User</span>
          </b-button>
        </div>
        <div class="card-subtitle mb-3">{{ seatsAvailableString }}</div>
        <div class="card-text">
          <div class="position-relative">
            <div v-if="!loadingIndicator" class="d-flex align-items-center justify-content-center position-absolute w-100 h-100"
            style="background-color: rgba(255, 255, 255, 0.7);
            z-index: 99;">
            <div class="loading">
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div>
          </div>


          <b-table ref="refUserListTable" class="position-relative" :items="usersData" responsive :fields="tableColumns"
            primary-key="uuid" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc">

            <!-- All column headers with no wrapping on text -->
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>

            <!-- Column: User -->
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar size="32" :src="data.item.icon_url"
                    :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                    :to="{ name: 'myorg-user-edit', params: { id: data.item.id } }" />
                </template>
                <b-link :to="{ name: 'myorg-user-edit', params: { id: data.item.id } }"
                  class="font-weight-bold d-block text-nowrap">
                  {{ data.item.name }}
                </b-link>
                <b-badge
                  v-if="data.item.enabled"
                  pill
                  :variant="`light-${resolveUserEnabledVariant(data.item.enabled)}`"
                  class="text-capitalize"
                >
                  Active
                </b-badge>
                <b-badge
                  v-else
                  pill
                  :variant="`light-${resolveUserEnabledVariant(data.item.enabled)}`"
                  class="text-capitalize"
                >
                  Inactive
                </b-badge>
              </b-media>
            </template>

            <template #cell(role)="data">
              <div class="d-flex">
                <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
                  :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
                <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
              </div>
            </template>

            <template #cell(manage)="data">

              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>

                <b-dropdown-item :to="{ name: 'myorg-user-edit', params: { id: data.item.id } }">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <div v-if="sessionUserData.id !== data.item.id">
                  <b-dropdown-item-button @click="toggleUserStatus(data.item)">
                    <feather-icon :icon="data.item.enabled ? 'UserXIcon' : 'UserCheckIcon'" />
                    <span class="align-middle ml-50">{{ data.item.enabled ? 'Disable' : 'Enable' }}</span>
                  </b-dropdown-item-button>
                </div>

                <div v-if="sessionUserData.id !== data.item.id">
                  <b-dropdown-item-button @click="openDeleteModal(data.item, deleteTargetItem)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item-button>
                </div>
              </b-dropdown>

            </template>

            <!-- Column: Date updated -->
            <template #cell(all_analytics_count)="data">
              <b-media vertical-align="center" class="text-center">
                {{ data.item.all_analytics_count }}
              </b-media>
            </template>

            <!-- Column: Date updated -->
            <template #cell(last_28_days_analytics_count)="data">
              <b-media vertical-align="center" class="text-center">
                {{ data.item.last_28_days_analytics_count }}
              </b-media>
            </template>



          </b-table>
        </div>


        </div>
      </div>
    </b-card>
    <b-modal id="modalDelete">
      <template #modal-header="{}">
        <h5>Confirm deletion</h5>
      </template>

      Are you sure you want to delete the user {{ deleteTargetItem.name }}?

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="primary"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="deleteTarget(deleteTargetItem.id); cancel();"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BLink, BBadge, BButton, BDropdown, BDropdownItemButton, BDropdownItem, BModal, BTable
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import useUsersList from '@/views/user/users-list/useUsersList'
import { avatarText } from '@core/utils/filter'
import UserListAddNew from '@/views/user/users-list/UserListAddNew.vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BModal,
    BDropdownItem,
    BDropdownItemButton,
    BDropdown,
    BButton,
    BCard,
    BLink,
    BBadge,
    BMedia,
    BAvatar,
    UserListAddNew,
    BTable
  },
  props: {
    orgData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    seatsAvailableString() {
      if (this.orgData.max_members === 0) return 'Unlimited seats available'

      const availableSeats = this.orgData.max_members - this.orgData.member_count
      return `${availableSeats < 0 ? 0 : availableSeats} seats remaining out of ${this?.orgData?.max_members} total`
    },
  },
  methods: {
    openDeleteModal(item, targetRef) {
      // Update modal data then open it
      targetRef.id = item.id
      targetRef.name = item.name
      this.$bvModal.show('modalDelete')
    },
  },
  setup(props) {
    const usersData = ref(null)
    const isAddNewUserSidebarActive = ref(false)
    const orgOptions = ref([])
    const sessionUserData = ref({})
    sessionUserData.value = useJwt.getUserData()

    const { roleOptions } = useUsersList()

    const {
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserEnabledVariant,
      refetchData,
      sortBy,
      isSortDirDesc,
    } = useUsersList()

    const toast = useToast()

    const tableColumns = [
      { key: 'name', label: 'User', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'role', label: 'Role', sortable: true },
      { key: 'last_active', label: 'Last active', sortable: true },
      { key: 'manage', label: 'Manage User', sortable: false },
    ]

    const loadingIndicator = ref(false)

    const toggleUserStatus = (user) => {
      loadingIndicator.value = false;
      if (user.enabled) {
        store.dispatch('app/updateUser', { id: user.id, userData: { enabled: false } })
          .then(() => {
            loadingIndicator.value = true;
            user.enabled = false;
          })
          .catch(error => {
            console.log(error);
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating user status.',
                text: `Couldn't update user status.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        store.dispatch('app/updateUser', { id: user.id, userData: { enabled: true } })
          .then(() => {
            loadingIndicator.value = true;
            user.enabled = true;
          })
          .catch(error => {
            console.log(error);
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating user status.',
                text: `Couldn't update user status.`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const deleteTargetItem = ref({ id: null, name: null })

    const deleteTarget = targetId => {
      store.dispatch('app/deleteUser', { id: targetId })
        .then(() => {
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }


    store.dispatch('app/fetchOrgMembers', { id: props.orgData.id, queryParams: { perPage: 100 } })
      .then(response => {
        loadingIndicator.value=true;
        usersData.value = response.data.results
      })
      .catch(error => {
        if (error.response.status === 404) {
          usersData.value = undefined
        }
      })

    return {
      isAddNewUserSidebarActive,
      usersData,
      roleOptions,
      orgOptions,
      deleteTargetItem,
      sessionUserData,
      toggleUserStatus,
      deleteTarget,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserEnabledVariant,
      avatarText,
      refetchData,
      loadingIndicator,

      sortBy,
      isSortDirDesc,
      tableColumns
    }
  },
}
</script>

<style>

</style>
