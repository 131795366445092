export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'User management',
  },
  {
    title: 'Organisations',
    route: 'orgs',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Permissions',
    route: 'permissions',
    icon: 'EyeIcon',
  },
  {
    header: 'Content',
  },
  {
    title: 'Topics',
    route: 'topics',
    icon: 'TagIcon',
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'FileTextIcon',
  },
  {
    title: 'Trial Reports',
    route: 'trial-reports',
    icon: 'FileTextIcon',
  },
  {
    title: 'Events',
    route: 'events',
    icon: 'CalendarIcon',
  },
  {
    title: 'Galleries',
    route: 'galleries',
    icon: 'ImageIcon',
  },
  {
    title: 'Videos',
    route: 'playlists',
    icon: 'VideoIcon',
  },
  {
    title: 'Trial Videos',
    route: 'trial-playlists',
    icon: 'VideoIcon',
  },
  {
    title: 'Files',
    route: 'files',
    icon: 'FileIcon',
  },
  {
    header: 'Wordpress Content',
  },
  {
    title: 'News',
    route: 'news-articles',
    icon: 'FileTextIcon',
  },
  {
    title: 'Knowledge Base',
    route: 'kb-articles',
    icon: 'FileTextIcon',
  },
  {
    title: 'Ask DWG',
    route: 'ask-dwg-articles',
    icon: 'FileTextIcon',
  },
  {
    header: 'Settings',
  },
  {
    title: 'Terms of use',
    route: 'terms',
    icon: 'InfoIcon',
  },
]
