<template>

  <div>

    <org-list-add-new
      :is-add-new-org-sidebar-active.sync="isAddNewOrgSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <orgs-list-filters
      :status-filter.sync="statusFilter"
      :status-options="enabledOptions"
      class="d-none"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              @click="isAddNewOrgSidebarActive = true"
            >
              <span class="text-nowrap">Add Org</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <div
        v-if="!requestDone"
        class="d-flex align-items-center justify-content-center position-absolute w-100 h-100"
        style="background-color: rgba(255, 255, 255, 0.7);"
      >
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>

      <b-table
        ref="refOrgListTable"
        class="position-relative"

        :items="fetchOrgs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      
        <!-- All column headers with no wrapping on text -->
        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>

        <!-- Column: Org -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.icon_url"
                :text="avatarText(data.item.name)"
                :to="{ name: 'orgs-view', params: { id: data.item.id } }"
                square
              />
            </template>
            <b-link
              :to="{ name: 'orgs-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <b-badge
              pill
              :variant="`light-${resolveOrgStatusVariant(data.item.status)}`"
            >
              {{ resolveOrgStatusText(data.item.status) }}
            </b-badge>
            <span v-if="data.item.is_trial && new Date(data.item.trial_expires) - new Date() > 0"> {{ 'Ends in ' + Math.floor((new Date(data.item.trial_expires) - new Date()) / (1000 * 60 * 60 * 24)) + ' days' }}</span>
            <span v-if="data.item.is_trial && !data.item.trial_expires"> No expiry</span>

          </b-media>
        </template>

        <!-- Column: Seats -->
        <template #cell(max_members)="data">
          <!-- Show "Unlimited" if max_members is set to 0 -->
          <span v-if="data.item.max_members === 0">Unlimited</span>
          <span v-else>{{ data.item.max_members }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'orgs-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'orgs-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item-button @click="openDeleteModal(data.item, deleteTargetItem)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrgs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal id="modalDelete">
      <template #modal-header="{}">
        <h5>Confirm deletion</h5>
      </template>


      Are you sure you want to delete the organisation {{ deleteTargetItem.name }}?

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">
          Cancel
        </b-button>

        <b-button size="sm" variant="danger" @click="deleteTarget(deleteTargetItem.id)">
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BModal, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import OrgsListFilters from './OrgsListFilters.vue'
import useOrgsList from './useOrgsList'
import OrgListAddNew from './OrgListAddNew.vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    OrgsListFilters,
    OrgListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BModal,
    BPagination,

    vSelect,
  },
  methods: {
    openDeleteModal(item, targetRef) {
      // Update modal data then open it
      targetRef.id = item.id
      targetRef.name = item.name
      this.$bvModal.show('modalDelete')
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const isAddNewOrgSidebarActive = ref(false)


    const deleteTargetItem = ref({ id : null, name : null })

    const deleteTarget = (targetId) => {
      store.dispatch('app/deleteOrg', { id: targetId })
        .then(() => {
          location.reload() // TODO: Can we reload only the table?
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting organisation.',
              text: 'ERROR: ' + error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      fetchOrgs,
      tableColumns,
      perPage,
      currentPage,
      totalOrgs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrgListTable,
      refetchData,
      requestDone,

      // UI
      resolveOrgRoleVariant,
      resolveOrgRoleIcon,
      resolveOrgEnabledVariant,
      resolveOrgStatusVariant,
      resolveOrgStatusText,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      enabledOptions,
      trialOptions,
    } = useOrgsList()

    return {

      // Sidebar
      isAddNewOrgSidebarActive,

      fetchOrgs,
      tableColumns,
      perPage,
      currentPage,
      totalOrgs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrgListTable,
      refetchData,
      requestDone,

      deleteTargetItem,
      deleteTarget,

      // Filter
      avatarText,

      // UI
      resolveOrgRoleVariant,
      resolveOrgRoleIcon,
      resolveOrgEnabledVariant,
      resolveOrgStatusVariant,
      resolveOrgStatusText,

      enabledOptions,
      trialOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
