<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          :src="contentItemData.thumbnail_url"
          :text="avatarText(contentItemData.name)"
          size="100px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ contentItemData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            accept=".jpg,.jpeg,.png,.webp,.gif"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span
            v-if="typeof contentItemData.thumbnail_url !== 'object'"
            class="d-none d-sm-inline"
          >
            Change Image
          </span>

          <span
            v-else
            class="d-none d-sm-inline"
          >
            Add Image
          </span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          v-if="typeof contentItemData.thumbnail_url !== 'object'"
          variant="outline-secondary"
          class="ml-1"
          @click="removeImage()"
        >
          <span class="d-none d-sm-inline">Remove Image</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- User Info: Input Fields -->
      <b-form
        autocomplete="off"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <!-- Field: Published Status -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Published status"
              rules="required"
            >
              <b-form-group
                label="Published status"
                label-for="published"
              >
                <v-select
                  v-model="contentItemData.published"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="published"
                  autofocus
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Published Date -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Published date"
            >
              <b-form-group
                label="Published date"
                label-for="published-at"
              >
                <b-form-datepicker
                  id="published-at"
                  v-model="contentItemData.published_at"
                  class="mb-1"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Featured -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Featured"
              label-for="featured"
            >
              <b-form-checkbox
                id="featured"
                v-model="contentItemData.featured"
                value="1"
                unchecked-value="0"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="$themeConfig.app.allowShowInFeed.includes(contentItemData.content_type)">
          <!-- Field: Show in news -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Show In News"
              label-for="show-in-news"
            >
              <b-form-checkbox
                id="show-in-news"
                v-model="contentItemData.news"
                value="1"
                unchecked-value="0"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Owner -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Owner"
            >
              <b-form-group
                label="Owner"
                label-for="owner"
              >
                <vue-autosuggest
                  v-model="contentItemData.owner.name"
                  input-id="owner"
                  :suggestions="filteredOptions"
                  :input-props="inputProps"
                  :get-suggestion-value="getSuggestionValue"
                  :limit="10"
                  @selected="onSelected"
                  @input="onInputChange"
                >
                  <template slot-scope="{suggestion}">
                    <div class="d-flex align-items-center">
                      <b-avatar :src="suggestion.item.photo_url" />
                      <div class="detail ml-50">
                        <b-card-text class="mb-0">
                          {{ suggestion.item.name }}
                        </b-card-text>
                        <small class="text-muted">
                          {{ suggestion.item.organisation.name }}
                        </small>
                      </div>
                    </div>
                  </template>
                </vue-autosuggest>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Topics -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Topics"
            >
              <b-form-group
                label="Topics"
                label-for="topics"
              >
                <v-select
                  v-model="contentItemData.topics"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="topicOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  multiple
                  input-id="topics"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Categories -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="category"
            >
              <b-form-group
                label="Category"
                label-for="category"
              >
                <v-select
                  v-model="contentItemData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryOptions"
                  :state="getValidationState(validationContext)"
                  taggable
                  push-tags
                  input-id="category"
                  @input="limiter"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Orgs -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Organisations"
            >
              <b-form-group
                label="Organisations"
                label-for="orgs"
              >
                <v-select
                  v-model="contentItemData.organisations"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="orgOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  multiple
                  input-id="orgs"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="contentType !== 'events'">
          <!-- Field: Event -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Belongs to event"
            >
              <b-form-group
                label="Belongs to event"
                label-for="event"
              >
                <v-select
                  v-model="contentItemData.parent_event"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  input-id="event"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Title -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required|min:3|max:100"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="contentItemData.title"
                  :state="getValidationState(validationContext)"
                  trim
                  autocomplete="off"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Slug -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Slug"
              rules="required|min:3|max:100"
            >
              <b-form-group
                label="Slug"
                label-for="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="contentItemData.slug"
                  :state="getValidationState(validationContext)"
                  trim
                  autocomplete="off"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="d-none">
          <!-- Field: Byline -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Byline"
            >
              <b-form-group
                label="Byline"
                label-for="byline"
              >
                <b-form-input
                  id="byline"
                  v-model="contentItemData.byline"
                  :state="getValidationState(validationContext)"
                  trim
                  autocomplete="off"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Excerpt -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Excerpt"
            >
              <b-form-group
                label="Excerpt"
                label-for="excerpt"
              >
                <b-form-textarea
                  id="excerpt"
                  v-model="contentItemData.excerpt"
                  rows="6"
                  :state="getValidationState(validationContext)"
                  trim
                  autocomplete="off"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Body -->
          <b-col
            cols="12"
            md="12"
          >
            <editor
              id="body"
              v-model="contentItemData.body"
              rows="20"
              trim
              autocomplete="off"
              api-key="o8d3io3d7ss0ytxqb5q82vp9rk2jbsuowdt2wgcao1iwd9rd"
              :init="{
                height: 500,
                menubar: false,
                invalid_elements : 'img,iframe,canvas,script,style,pre',
                block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
                plugins: ['code','link','table','paste','lists'],
                smart_paste: false,
                toolbar: [
                  'undo redo | removeformat | formatselect | bold italic | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | link unlink | table tabledelete | help | code',

                ],
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
              }"
            />
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Tags -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Tags"
            >
              <b-form-group
                label="Search keywords"
                label-for="tags"
              >
                <b-form-tags
                  v-model="contentItemData.tags"
                  input-id="tags"
                  separator=","
                  remove-on-delete
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          v-if="!working"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Save Changes
        </b-button>
        <b-button
          v-if="!working"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: contentType + '-view', params: { id: contentItemData.id } }"
        >
          Cancel
        </b-button>
        <b-button
          v-if="working"
          disabled
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <feather-icon
            icon="RefreshCwIcon"
            animation="spin"
          /> Updating<span v-if="contentItemData.content_type === 'App\\Models\\Event'"> Eventbrite</span>...
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BForm, BFormGroup, BFormInput,
  BFormTags, BFormTextarea, BFormDatepicker, BFormInvalidFeedback, BCardText, BFormCheckbox
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Editor from '@tinymce/tinymce-vue'

import { $themeConfig } from '@themeConfig'
import useContentItemsList from '../content-items-list/useContentItemsList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BFormInvalidFeedback,
    BForm,
    BFormTags,
    BFormCheckbox,
    vSelect,
    VueAutosuggest,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    Editor,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      timeout: null,
      debounceMilliseconds: 250,
      selected: null,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
      },
      limit: 10,
    }
  },
  computed: {
    $themeConfig() {
      return $themeConfig
    },
  },
  methods: {
    getSuggestionValue(suggestion) {
      return suggestion.item.name
    },
    onSelected(option) {
      this.selected = option.item
      this.$props.contentItemData.user_id = option.item.id
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.datasuggest = undefined
        return
      }

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        store.dispatch('app/fetchUsers', {
          q: text,
          sortBy: 'name',
        })
          .then(response => {
            this.filteredOptions = [{
              data: response.data.results,
            }]
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.datasuggest = undefined
            }
          })
      }, this.debounceMilliseconds)
    },
    limiter(e) {
      if (e.length > 1) {
        e.pop()
      }
    },
  },
  setup(props) {
    // Use toast
    const toast = useToast()
    const contentTypeUrl = ref(router.currentRoute.name)

    const { statusOptions } = useContentItemsList()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.contentItemData.thumbnail_url = base64
    })

    const removeImage = () => {
      // eslint-disable-next-line no-param-reassign
      props.contentItemData.thumbnail_url = null
    }

    const contentType = router.currentRoute.path.split('/')[1]
    const orgOptions = ref([])
    const eventOptions = ref([])
    const topicOptions = ref([])
    const categoryOptions = ref([]);
    const working = ref(false)

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const onSubmit = () => {
      working.value = true

      // if 'show in news feed' not visible then force to 0
      if (!$themeConfig.app.allowShowInFeed.includes(props.contentItemData.content_type)) props.contentItemData.news = 0

      if (props.contentItemData.category && props.contentItemData.category.label) props.contentItemData.category = props.contentItemData.category.label
      store.dispatch('app/updateContentItem', { contentType, id: router.currentRoute.params.id, contentItemData: props.contentItemData })
        .then(() => {
          router.push({ name: `${contentType}-view`, params: { id: router.currentRoute.params.id } })
        })
        .catch(error => {
          working.value = false
          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: `Error updating ${contentTypeUrl.value}.`,
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    store.dispatch('app/fetchOrgs', { perPage: 1000, sortBy: 'name' })
      .then(response => {
        orgOptions.value = response.data.results.map(item => ({ label: item.name, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          orgOptions.value = undefined
        }
      })

    store.dispatch('app/listContentItems', { contentType: 'events', queryParams: { perPage: 1000, sortBy: 'name' } })
      .then(response => {
        eventOptions.value = response.data.results.map(item => ({ label: item.title, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          eventOptions.value = undefined
        }
      })

    store.dispatch('app/fetchTopics', { perPage: 1000, sortBy: 'name' })
      .then(response => {
        topicOptions.value = response.data.results.map(item => ({ label: item.name, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          topicOptions.value = undefined
        }
      })
    store.dispatch('app/fetchCategories', { contentType, queryParams: { perPage: 1000, sortBy: 'name' } })
    .then(response => {
      categoryOptions.value = response.data.results.map(item => ({ label: item.category, value: item.category }))
    })
    .catch(error => {
      if (error.response.status === 404) {
        categoryOptions.value = undefined
      }
    })

    return {
      avatarText,
      statusOptions,
      contentType,

      // form
      onSubmit,
      refFormObserver,
      getValidationState,

      // Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      removeImage,

      orgOptions,
      eventOptions,
      topicOptions,
      categoryOptions,
      working,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
pre{
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: .5rem;
}
</style>
