<template>
  <content-items-view
    content-type="trial-playlists"
    content-type-singular="trial-playlist"
    content-name="Trial Playlists"
    content-name-singular="Trial Playlist"
  />
</template>

<script>
import ContentItemsView from '@/views/content-item/content-items-view/ContentItemsView.vue'
import ContentItemsList from '@/views/content-item/content-items-list/ContentItemsList.vue'

export default {
  components: {
    ContentItemsList,
    ContentItemsView,
  },
}
</script>

<style scoped>

</style>
