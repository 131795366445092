<template>
  <div>

    <!-- Header: Event data -->
    <div class="d-flex">
      <feather-icon
        icon="PhotoIcon"
        size="19"
      />
      <h4 class="mb-0">
        Event Details
      </h4>
    </div>

    <!-- Form: Event Form -->
    <b-form class="mt-1">
      <b-row>

        <b-col
          cols="12"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <!-- Event Details: Input Fields -->
            <b-form
              autocomplete="off"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >

              <b-row>
                <!-- Field: Start Date -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Start Date"
                    rules="required"
                  >
                    <b-form-group
                      label="Start Date"
                      label-for="start-date"
                    >
                      <b-form-datepicker
                        id="start-date"
                        v-model="start_date"
                        class="mb-1"
                      />
                      <b-form-timepicker
                        id="start-time"
                        v-model="start_time"
                        class="mb-1"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: End Date -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="End Date"
                    rules="required"
                  >
                    <b-form-group
                      label="End Date"
                      label-for="end-date"
                    >
                      <b-form-datepicker
                        id="end-date"
                        v-model="end_date"
                        class="mb-1"
                        :min="end_min"
                      />
                      <b-form-timepicker
                        id="end-time"
                        v-model="end_time"
                        class="mb-1"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Online Event"
                    label-for="online_event"
                  >
                    <b-form-checkbox
                      id="online_event"
                      v-model="contentItemData.content.online_event"
                      :value="1"
                      :unchecked-value="0"
                    >
                      This is an Online Event
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <template v-if="!contentItemData.content.online_event">
                  <!-- Field: Venue Name -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Venue Name"
                      rules="required|min:3|max:100"
                    >
                      <b-form-group
                        label="Venue Name"
                        label-for="venue_name"
                      >
                        <b-form-input
                          id="venue_name"
                          v-model="contentItemData.content.venue_name"
                          :state="getValidationState(validationContext)"
                          trim
                          autocomplete="off"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Field: Venue Address -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Venue Address Line 1"
                      rules="required|min:3|max:100"
                    >
                      <b-form-group
                        label="Venue Address Line 1"
                        label-for="venue_address_line_1"
                      >
                        <b-form-input
                          id="venue_address_line_1"
                          v-model="contentItemData.content.venue_address_line_1"
                          :state="getValidationState(validationContext)"
                          trim
                          autocomplete="off"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Venue Address Line 2"
                      rules="min:3|max:100"
                    >
                      <b-form-group
                        label="Venue Address Line 2"
                        label-for="venue_address_line_2"
                      >
                        <b-form-input
                          id="venue_address_line_2"
                          v-model="contentItemData.content.venue_address_line_2"
                          :state="getValidationState(validationContext)"
                          trim
                          autocomplete="off"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Venue City"
                      rules="required|min:3|max:100"
                    >
                      <b-form-group
                        label="Venue City"
                        label-for="venue_city"
                      >
                        <b-form-input
                          id="venue_city"
                          v-model="contentItemData.content.venue_city"
                          :state="getValidationState(validationContext)"
                          trim
                          autocomplete="off"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Venue Postcode"
                      rules="required|min:3|max:100"
                    >
                      <b-form-group
                        label="Venue Postcode"
                        label-for="venue_postcode"
                      >
                        <b-form-input
                          id="venue_postcode"
                          v-model="contentItemData.content.venue_postcode"
                          :state="getValidationState(validationContext)"
                          trim
                          autocomplete="off"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </template>

                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Ticket Price"
                    rules="required"
                  >
                    <b-form-group
                      label="Ticket Price"
                      label-for="ticket_price"
                    >
                      <b-form-input
                        id="ticket_price"
                        v-model="contentItemData.content.ticket_price"
                        :state="getValidationState(validationContext)"
                        trim
                        autocomplete="off"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Event Capacity"
                    rules="required"
                  >
                    <b-form-group
                      label="Event Capacity"
                      label-for="event_capacity"
                    >
                      <b-form-input
                        id="event_capacity"
                        v-model="contentItemData.content.event_capacity"
                        :state="getValidationState(validationContext)"
                        trim
                        autocomplete="off"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Eventbrite Link -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Eventbrite Link"
                    label-for="eventbrite_link"
                  >
                    <a :href="contentItemData.content.eventbrite_link">
                      {{ contentItemData.content.eventbrite_link }}
                    </a>

                    <p v-if="!contentItemData.content.eventbrite_link">
                      This event has not been published to EventBrite yet
                    </p>
                  </b-form-group>
                </b-col>

              </b-row>

              <!-- Action Buttons -->
              <b-button
                v-if="!working"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Save Changes
              </b-button>
              <b-button
                v-if="!working"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :to="{ name: 'events-view', params: { id: contentItemData.id } }"
              >
                Cancel
              </b-button>
              <b-button
                v-if="working"
                disabled
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Updating Eventbrite...
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>

      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormTags, BFormCheckbox, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
// import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTags,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      start_date: this.contentItemData.content.start?.split('T')?.[0],
      start_time: this.contentItemData.content.start?.split('T')?.[1]?.split('.')?.[0],
      end_date: this.contentItemData.content.end?.split('T')?.[0],
      end_time: this.contentItemData.content.end?.split('T')?.[1]?.split('.')?.[0],
      working: false,
    }
  },
  computed: {
    // Used to piece together the datetime string
    // from their respective date and time values
    start_datetime() {
      return `${this.start_date?.split('T')?.[0]}T${this.start_time}.000000Z`
    },
    end_datetime() {
      return `${this.end_date?.split('T')?.[0]}T${this.end_time}.000000Z`
    },
    end_min() {
      return new Date(this.start_datetime)
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // onSubmit,
      toast,
      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    onSubmit() {
      this.working = true
      store.dispatch(
        'app/updateContentItem',
        {
          contentType: 'events',
          id: router.currentRoute.params.id,
          contentItemData: {
            ...this.contentItemData.content,
            start: this.start_datetime,
            end: this.end_datetime,
          },
        },
      )
        .then(() => {
          router.push({ name: 'events-view', params: { id: router.currentRoute.params.id } })
        })
        .catch(error => {
          this.working = false

          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Event.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
