<template>
  <div>

    <!-- Header: Event data -->
    <div class="d-flex">
      <feather-icon
        icon="PhotoIcon"
        size="19"
      />
      <h4 class="mb-0">
        Event Attendees
      </h4>
    </div>

    <!-- Form: Event Form -->
    <b-form class="mt-1">
      <b-row class="mb-2">
        <b-col cols="6" md="4" v-for="attendee in contentItemData.content.attendees" :key="attendee.id">
          <div class="mb-1 d-flex">
            <b-avatar :src="attendee.photo_url" size="40px" class="mr-1" />
            <div class="d-flex flex-column justify-content-center">
              <div class="text-bold">{{ attendee.name }}</div>
              <div>{{ attendee.organisation_name }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <!-- Event Details: Input Fields -->
            <b-form
              autocomplete="off"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >

              <b-row>
                <!-- Field: Non-Member Guests -->
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Non-member Guests"
                  >
                    <b-form-group
                      label="Non-member Guests"
                      label-for="guests"
                    >
                      <b-form-tags
                        v-model="contentItemData.content.guests"
                        input-id="guests"
                        separator=","
                        remove-on-delete
                        placeholder="Add a guest"
                      />


                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- Action Buttons -->
              <b-button
                v-if="!working"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Save Changes
              </b-button>
              <b-button
                v-if="!working"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :to="{ name: 'events-view', params: { id: contentItemData.id } }"
              >
                Cancel
              </b-button>
              <b-button
                v-if="working"
                disabled
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                Updating Eventbrite...
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>

      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BAvatar, BRow, BCol, BForm, BFormGroup, BFormInput, BFormTags, BFormCheckbox, BFormInvalidFeedback, BButton, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
// import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTags,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormTimepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      start_date: this.contentItemData.content.start?.split('T')?.[0],
      start_time: this.contentItemData.content.start?.split('T')?.[1]?.split('.')?.[0],
      end_date: this.contentItemData.content.end?.split('T')?.[0],
      end_time: this.contentItemData.content.end?.split('T')?.[1]?.split('.')?.[0],
      working: false,
    }
  },
  computed: {
    // Used to piece together the datetime string
    // from their respective date and time values
    start_datetime() {
      return `${this.start_date?.split('T')?.[0]}T${this.start_time}.000000Z`
    },
    end_datetime() {
      return `${this.end_date?.split('T')?.[0]}T${this.end_time}.000000Z`
    },
    end_min() {
      return new Date(this.start_datetime)
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      // onSubmit,
      toast,
      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    onSubmit() {
      this.working = true
      store.dispatch(
        'app/updateContentItem',
        {
          contentType: 'events',
          id: router.currentRoute.params.id,
          contentItemData: {
            ...this.contentItemData.content,
            start: this.start_datetime,
            end: this.end_datetime,
          },
        },
      )
        .then(() => {
          router.push({ name: 'events-view', params: { id: router.currentRoute.params.id } })
        })
        .catch(error => {
          this.working = false

          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Event.',
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
