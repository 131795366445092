export default function globalLists() {
  const statusOptions = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ]

  const contentTypes = [
    { label: 'Reports', value: 'App\\Models\\Report' },
    { label: 'Trial Reports', value: 'App\\Models\\TrialReport' },
    { label: 'Galleries', value: 'App\\Models\\Gallery' },
    { label: 'Videos', value: 'App\\Models\\Playlist' },
    { label: 'Trial Videos', value: 'App\\Models\\TrialPlaylist' },
    { label: 'Files', value: 'App\\Models\\File' },
    { label: 'News', value: 'Wordpress\\news' },
    { label: 'Research Blog', value: 'Wordpress\\research-blog' },
    { label: 'Knowledge Base', value: 'Wordpress\\good-practice' },
    { label: 'Ask DWG', value: 'Wordpress\\ask-dwg' },
    { label: 'Events', value: 'App\\Models\\Event' },
  ]

  return {
    statusOptions,
    contentTypes,
  }
}
