<template>

  <div>

    <content-items-list-add-new
      :is-add-new-content-item-sidebar-active.sync="isAddNewContentItemSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <content-items-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      class="d-none"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              @click="isAddNewContentItemSidebarActive = true"
            >
              <span class="text-nowrap">Add new</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refContentItemListTable"
        class=""
        :items="fetchContentItems"
        responsive
        :fields="tableColumns"
        primary-key="uuid"
        :sort-by.sync="sortBy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>

        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.icon_url"
                :text="avatarText(data.item.title)"
                :to="{ name: $props.contentType+'-view', params: { id: data.item.id } }"
                rounded
              />
            </template>
            <b-link
              :to="{ name: $props.contentType+'-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
              :title=data.item.title
              >
              <b-badge
                v-if="data.item.featured"
                pill
                variant="primary"
                class="text-capitalize"
              >
                <feather-icon
                  icon="StarIcon"
                />
              </b-badge>
              {{ data.item.title.length > 40 ? data.item.title.slice(0, 40).trim() + '...' : data.item.title.trim()}}
              <b-badge
                v-if="data.item.published"
                pill
                :variant="`light-${resolveContentItemStatusVariant(data.item.published)}`"
                class="text-capitalize"
              >
                Published
              </b-badge>
              <b-badge
                v-else
                pill
                :variant="`light-${resolveContentItemStatusVariant(data.item.published)}`"
                class="text-capitalize"
              >
                Draft
              </b-badge>
            </b-link>
          </b-media>
        </template>

        <!-- Column: Date published -->
        <template #cell(published_at)="data">
          <b-media
            v-if="data.item.published_at"
            vertical-align="center"
          >
            {{ formatDate(data.item.published_at) }}
          </b-media>
        </template>

        <!-- Column: Event start date -->
        <template #cell(content.start)="data">
          <b-media
            v-if="data.item.content && data.item.content.start"
            vertical-align="center"
          >
            {{ formatDate(data.item.content.start) }}
          </b-media>
        </template>

        <!-- Column: Date updated -->
        <template #cell(updated_at)="data">
          <b-media
            v-if="data.item.updated_at"
            vertical-align="center"
          >
            {{ formatDate(data.item.updated_at) }}
          </b-media>
        </template>

        
        <!-- Column: Total Views -->
        <template #cell(content.all_analytics_count)="data">
          <b-media
            v-if="data.item.content"
            vertical-align="center"
            class="text-center"
          >
            {{ data.item.content.all_analytics_count }}
          </b-media>
        </template>

        <!-- Column: Views (Prev 28 Days) -->
        <template #cell(content.last_28_days_analytics_count)="data">
          <b-media
            v-if="data.item.content"
            vertical-align="center"
            class="text-center"
          >
            {{ data.item.content.last_28_days_analytics_count }}
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: $props.contentType+'-view', params: { id: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: $props.contentType+'-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item-button @click="openDeleteModal(data.item, deleteTargetItem)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContentItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal id="modalDelete">
      <template #modal-header="{}">
        <h5>Confirm deletion</h5>
      </template>

      Are you sure you want to delete {{ deleteTargetItem.name }}?

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="primary"
          @click="cancel()"
        >
          Cancel
        </b-button>

        <b-button
          size="sm"
          variant="danger"
          @click="deleteTarget(deleteTargetItem.id); cancel();"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BModal, BLink,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ContentItemsListFilters from './ContentItemsListFilters.vue'
import useContentItemsList from './useContentItemsList'
import ContentItemsListAddNew from './ContentItemsListAddNew.vue'
import formatDateMixin from '../../../@core/mixins/ui/date'

// Notification

export default {
  components: {
    ContentItemsListFilters,
    ContentItemsListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BModal,
    BPagination,
    BAvatar,

    vSelect,
  },
  mixins: [formatDateMixin],
  props: {
    contentType: {
      type: String,
      required: true,
    },
    contentTypeSingular: {
      type: String,
      required: true,
    },
    contentName: {
      type: String,
      required: true,
    },
    contentNameSingular: {
      type: String,
      required: true,
    },
  },
  methods: {
    openDeleteModal(item, targetRef) {
      // Update modal data then open it
      targetRef.id = item.id
      targetRef.name = item.name
      this.$bvModal.show('modalDelete')
    },
  },
  setup(props) {
    const {
      fetchContentItems,
      tableColumns,
      perPage,
      currentPage,
      totalContentItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContentItemListTable,
      refetchData,

      // UI
      resolveContentItemStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,
    } = useContentItemsList(props)

    // Use toast
    const toast = useToast()

    const isAddNewContentItemSidebarActive = ref(false)

    const deleteTargetItem = ref({ id: null, name: null })

    const contentType = router.currentRoute.path.split('/')[1]

    const deleteTarget = targetId => {
      store.dispatch('app/deleteContentItem', { contentType, id: targetId })
        .then(() => {
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error deleting item.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }


    return {

      // Sidebar
      isAddNewContentItemSidebarActive,

      fetchContentItems,
      tableColumns,
      perPage,
      currentPage,
      totalContentItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContentItemListTable,
      refetchData,

      deleteTargetItem,
      deleteTarget,

      // Filter
      avatarText,

      // UI
      resolveContentItemStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
